<template>
  <div class="manage">
   
  </div>
</template>

<script>

export default {
  name: "User",
 
};
</script>
<style lang="less" scoped>
.manage-header {
  display: flex;
  justify-content: space-between;
}
</style>
